import React from 'react';
import SEO from '../../components/seo';
import GrandPrixPage from '../../components/desktop/GrandPrixPage/GrandPrixPage';

const Artist = () => {
  return (
    <>
      <SEO title={'1925'} />
      <GrandPrixPage bgColor="#E0C887" />
    </>
  );
};

export default Artist;
