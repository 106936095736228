import React from 'react';
import {SectionText, SectionTitle} from '../../atoms';
import {
  Container,
  PageContainer,
  ColorWrapper,
  TextSpaceMargin,
  ImageMove,
} from './styles';
import pic1 from './images/1.jpg';
import pic2 from './images/2.jpg';
import pic3 from './images/3.jpg';
import pic4 from './images/4.jpg';
import pic5 from './images/5.jpg';
import pic6 from './images/6.jpg';
import MovedImg from './images/grand_prix.png';
import Gallery from '../Gallery/Gallery';
import PlayerGrey from '../../atoms/PlayerGrey/PlayerGrey';
import img1 from './images/Ekspozycja w pawilonie polskim. kopia.jpg';
import img2 from './images/Jan Szczepkowski na tle montażu kapliczki na wystawie paryskiej kopia.jpg';
import img3 from './images/Jan Szczepkowski w okresie wystawy paryskiej.jpg';
import img4 from './images/Kapliczka Bożego Narodzenia NAC kopia.jpg';
import img5 from './images/Kapliczka Bożego Narodzenia w Pawilonie Polskim, 1925 r. kopia.jpg';

const GrandPrixPage = ({bgColor}) => {
  return (
    <PageContainer backgroundColor={bgColor}>
      <Container>
        <ImageMove src={MovedImg} />
        <TextSpaceMargin>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <SectionTitle color="#525252">GRAND PRIX</SectionTitle>
            <PlayerGrey src={require('../../../audio/Grand Prix.mp3')} />
          </div>
          <SectionText color="#525252" style={{paddingTop: '1.5rem'}}>
            &emsp;&emsp;Rok 1925 był dla Jana Szczepkowskiego przełomowy. Na
            Międzynarodowej Wystawie Sztuki Dekoracyjnej i&nbsp;Przemysłowej
            (l'Exposition internationale des Arts décoratifs et industriels
            modernes), która odbywała się w&nbsp;Paryżu od 28 kwietnia do
            października tegoż roku, artysta został wyróżniony Grand Prix za
            swoją kapliczkę Bożego Narodzenia oraz Diplome d'Honneur za system
            nauczania.
            <br />
            <br />
            &emsp;&emsp;Tradycja organizacji wystaw w&nbsp;formie współczesnej
            odbyła się po raz pierwszy w&nbsp;Londynie w&nbsp;roku 1851.
            Pierwotnie paryską wystawę planowano na rok 1915, ale udaremniła ją
            pierwsza wojna światowa. W&nbsp;myśl przyjętej od początku
            organizacji wystaw światowych zasady, że uczestnikami mogą być
            wyłącznie państwa, udział polskiego artysty wiązał się dotąd
            z&nbsp;dylematem moralnym i&nbsp;koniecznością reprezentacji państwa
            zaborcy. Rok 1925 okazał się dla młodego państwa Polskiego szansą na
            debiut, pokazanie swojego samodzielnego myślenia i&nbsp;siły
            twórczej jaka tkwiła w&nbsp;odrodzonym narodzie. Pisał o&nbsp;tym
            już w&nbsp;maju 1921 r. na łamach „Przemysłu i&nbsp;Rzemiosła” Jerzy
            Warchałowski: "Na wystawie paryskiej Polska musi pokazać oblicze -
            odrębne. Doborem wystawionych dzieł, największym wysiłkiem polskiej
            sztuki i&nbsp;techniki musi zdobyć należne miejsce w&nbsp;rzędzie
            narodów łączących się nareszcie po wojnie w&nbsp;pokojowej pracy".
            <br />
            <br />
            &emsp;&emsp;Jerzy Warchałowski odegrał znaczącą rolę
            w&nbsp;przyszłym sukcesie polskiego pawilonu. Został mianowany
            Generalnym Delegatem Polski na Wystawę a&nbsp;jego dobór artystów
            określił charakter polskiej ekspozycji poszukującej stylu narodowego
            niedawno odrodzonego państwa. Styl ten udało się odnaleźć.
            W&nbsp;trzy lata po zakończeniu wystawy napisał: „tam dopiero, nad
            brzegami Sekwany odzyskaliśmy naszą niepodległość artystyczną”.
            <br />
            <br />
            &emsp;&emsp;Wystawa Światowa roku 1925 zmieniła jednak więcej.
            Ostatecznie postanowiono odejść od goszczącej w&nbsp;sztuce
            użytkowej od ostatniego dziesięciolecia XIX wieku secesji i&nbsp;jej
            swobody układów kompozycyjnych, asymetrii i&nbsp;bogatej roślinnej
            ornamentyki na rzecz gładkich, krystalicznie ciętych geometrycznych
            płaszczyzn. Właśnie wtedy rozpoczął się szczyt popularności Art déco
            – stylu w&nbsp;sztuce, zawdzięczającej swoją nazwę francuskiej
            nazwie wystawy Arts décoratifs czyli Art – sztuka i&nbsp;décoratif –
            dekoracyjny, w&nbsp;rozumieniu jakie język polski łączy
            z&nbsp;urządzaniem wnętrz (czyli „dekorowaniem”). Źródła francuskie
            twierdzą, że wystawa przyciągnęła 16 mln gości.
          </SectionText>
        </TextSpaceMargin>
      </Container>
      <ColorWrapper bgColor="#525252" style={{marginTop: '3rem'}}>
        <Container>
          <Gallery
            maxWidth={100}
            isFlex
            hoverColor="rgba(224, 200, 135, 0.4)"
            colorSign="#fbfbfb"
            images={[
              {
                src: pic1,
                sign:
                  'Budowa pawilonu polskiego. Przed budynkiem członkowie komitetu organizacyjnego, od lewej: doradca techniczny, architekt Tadeusz Stryjeński (ówczesny teść Zofii Stryjeńskiej); komisarz generalny sekcji polskiej Jerzy Warchałowski, delegat Rządu Polskiego do spraw wystawy sztuki dekoracyjnej, rzeźbiarz Ludwik Puget; autor projektu pawilonu polskiego architekt Józef Czajkowski. 1925 R.',
                width: '30.1%',
              },
              {
                src: pic2,
                sign:
                  'Komisarz generalny sekcji polskiej Jerzy Warchałowski przed wejściem budowanego pawilonu polskiego. 1925 R.',
                width: '31.35%',
              },
              {
                src: pic3,
                sign:
                  'Otwarcie polskiego pawilonu podczas międzynarodowej wystawy. Widok zewnętrzny. 1925 R.',
                width: '33.1%',
              },
            ]}
          />
        </Container>
      </ColorWrapper>
      <ColorWrapper bgColor={'#E0C887'}>
        <Container style={{paddingTop: '3rem'}}>
          <SectionText color="#525252">
            &emsp;&emsp;Taka jednolitość polskiego pawilonu przy zachowaniu
            wysokiego poziomu wszystkich prac przełożyła się na wielki sukces
            polskich wystawców w&nbsp;postaci wielu nagród. Choć tutaj nie ma
            zgodności co do ich faktycznej ilości, bo w&nbsp;sprawozdaniu
            "Polska sztuka dekoracyjna" (1927) Warchałowski zapisał: "Eksponaty
            nasze poza pochlebną, często entuzjastyczną oceną w&nbsp;pismach,
            zdobyły 189 nagród, w&nbsp;tem 36 Grand-Prix, 31 dyplomów
            honorowych, 60 medali złotych, przy czem w&nbsp;najważniejszych
            działach: umeblowanych wnętrz, uzyskaliśmy największą
            w&nbsp;porównaniu z&nbsp;innymi ilość Grand-Prix, a&nbsp;mianowicie
            6, oraz w&nbsp;dziale tkanin również największą ilość obok Austrii,
            a&nbsp;mianowicie po 7".
            <br />
            <br />
            &emsp;&emsp;Z&nbsp;kolei na 172 nagrody „wyliczył” polski sukces
            Mieczysław Tretera w&nbsp;"Sztukach Pięknych" (nr 2, 1926). Treter
            zauważył: „Polska otrzymała łącznie 172 nagrody. Wystawców polskich
            oficjalnie podanych było 307, ale w&nbsp;liczbie tej byli także
            profesorowie szkół artystycznych, którym nagród nie przyznawano. Po
            odliczeniu 56 profesorów mamy 251 faktycznych wystawców. Liczba 172
            nagród stanowi więc 68,5%. Dzięki takiemu stosunkowi liczby
            nagrodzonych do liczby wystawców Polska zajęła pierwsze miejsce.”.
            <br />
            <br />
            &emsp;&emsp;Zdaniem Anny M. Dexlerowej i&nbsp;Andrzeja K.
            Olszewskiego, autorów monografii "Polska i&nbsp;Polacy na
            powszechnych wystawach światowych 1851-2000": "Polacy zdobyli
            w&nbsp;Paryżu: 35 nagród Grand Prix (m.in. dla Józefa Czajkowskiego,
            Henryka Kuny, Zofii Stryjeńskiej, Józefa Mehoffera, Wojciecha
            Jastrzębowskiego, Warsztatów Krakowskich, dla szkół), 31 nagrody
            Diplome d'Honneur, 70 złotych medali, 56 medali srebrnych i&nbsp;13
            brązowych, w&nbsp;sumie aż 205 nagród".
            <br />
            <br />
            &emsp;&emsp;Na Wystawie zostali nagrodzeni także uczniowie
            zakopiańskiej szkoły, za rzeźby, a&nbsp;w zasadzie drewniane figurki
            nawiązujące do prymitywizmu sztuki podhalańskiej. Na przybitych do
            postumentu złotych tabliczkach wskazuje się na zbiorowego autora
            sygnowanego jako „szkoła zakopiańska”.
            <br />
            <br />
            &emsp;&emsp;Najbardziej spektakularnym sukcesem był zakup przez rząd
            francuski Kapliczki Bożego Narodzenia Jana Szczepkowskiego.
            Szczepkowski otrzymał za ołtarz Grand Prix i&nbsp;Legię Honorową.
            Otworzyło to także najaktywniejszy okres w&nbsp;życiu zawodowym
            artysty.
          </SectionText>
          <Gallery
            maxWidth={'60'}
            columnsNumber={2}
            isImgFit
            hoverColor="rgba(224, 200, 135, 0.4)"
            colorSign="#525252"
            images={[
              {
                src: pic4,
                sign: 'Grand Prix',
              },
              {
                src: pic5,
                sign: 'Legia Honorowa',
              },
            ]}
          />
          <SectionText color="#525252">
            &emsp;&emsp;Kapliczka wpisywałą się w&nbsp;koncepcję Warchałowskiego
            idealnie spełniając jego kryterium polskości. Odwoływała się do
            stylu zakopiańskiego, inspirowana motywami ludowymi, jej autor
            rozumiał tak ważny na Warchałowskiego ludowy rys. Jednocześnie była
            nowoczesna. <br />
            <br />
            &emsp;&emsp;Kapliczkę zakupił rząd francuski, choć jak zauważa na
            podstawie korespondencji Warchałowskiego Katarzyna Chrudzimska –
            Uhera interesowali się nim także Anglicy i&nbsp;Amerykanie. Po
            początkowym zainteresowaniu rządu francuskiego (łączącego się choćby
            z deklaracją wystawienia dzieła w&nbsp;Muzeum Sztuki Dekoracyjnej
            w&nbsp;Luwrze), entuzjazm Francuzów opadł. Ostatecznie w&nbsp;wyniku
            negocjacji ołtarz sprzedano za 25 000 franków co stanowiło jedną
            trzecią ceny, od której rozpoczęto rozmowy. Szczepkowski nalegał,
            aby ołtarz znalazł się albo we wspomnianym muzeum albo w&nbsp;jednym
            z&nbsp;kościołów na terenie Paryża. W&nbsp;wyniku negocjacji
            przeprowadzanych przez Warchałowskiego ołtarz umieszczono
            ostatecznie w&nbsp;polskim kościele pod wezwaniem św. Stanisława
            w&nbsp;Dourges. Miasto mieści się w&nbsp;rejonie Pas-de-Calais
            oddalonym około stu kilometrów od Paryża. Ciekawostką jest fakt, że
            po II&nbsp;wojnie światowej wśród miejscowych powstało błędne
            przekonanie, że autorem ołtarza jest Władysław Skoczylas, którego
            drzeworyty znajdują się w&nbsp;kościele.
          </SectionText>
          <Container style={{marginTop: '2rem'}}>
            <p
              style={{
                fontFamily: 'LATOREGULAR',
                fontSize: '10px',
                lineHeight: 1.2,
                textAlign: 'justify',
                color: '#525252',
              }}>
              Źródło informacji:
              <br />
              <br />
              Chrudzimska – Uhera Katarzyna, JAN SZCZEPKOWSKI, ŻYCIE I
              TWÓRCZOŚĆ, Urząd Miejski w Milanówku, 2008 r.
              <br />
            </p>
            <a
              style={{
                fontFamily: 'LATOREGULAR',
                fontSize: '10px',
                lineHeight: 1.2,
                textAlign: 'justify',
                color: '#525252',
                marginTop: '10px',
              }}
              href={'https://culture.pl/pl/artykul/pawilony-polskie'}>
              https://culture.pl/pl/artykul/pawilony-polskie
            </a>
            <br />

            <a
              style={{
                fontFamily: 'LATOREGULAR',
                fontSize: '10px',
                lineHeight: 1.2,
                textAlign: 'justify',
                color: '#525252',
              }}
              href={
                'https://wilno.tvp.pl/47787010/95-lat-temu-na-wystawie-w-paryzu-narodzilo-sie-polskie-art-deco'
              }>
              https://wilno.tvp.pl/47787010/95-lat-temu-na-wystawie-w-paryzu-narodzilo-sie-polskie-art-deco
            </a>
            <br />

            <a
              style={{
                fontFamily: 'LATOREGULAR',
                fontSize: '10px',
                lineHeight: 1.2,
                textAlign: 'justify',
                color: '#525252',
              }}
              href={
                'https://opolnocywparyzu.pl/miedzynarodowa-wystawa-sztuki-dekoracyjnej-i-wzornictwa-1925-w-paryzu/'
              }>
              https://opolnocywparyzu.pl/miedzynarodowa-wystawa-sztuki-dekoracyjnej-i-wzornictwa-1925-w-paryzu/
            </a>
          </Container>
          <Gallery
            style={{gridGap: '2rem 1rem'}}
            maxWidth={'100'}
            columnsNumber={3}
            isImgFit
            hoverColor="rgba(224, 200, 135, 0.4)"
            colorSign="#525252"
            images={[
              {
                src: pic6,
                sign:
                  ' Ekspozycja w&nbsp;pawilonie polskim. Kaplica autorstwa rzeźbiarza, prof. Jana Szczepkowskiego.',
              },
              {
                src: img1,
                sign: 'Ekspozycja w pawilonie polskim',
              },
              {
                src: img2,
                sign:
                  'Jan Szczepkowski na tle montażu kapliczki na wystawie paryskiej',
              },
              {
                src: img3,
                sign: 'Jan Szczepkowski w okresie wystawy paryskiej',
              },
              {
                src: img4,
                sign: 'Kapliczka Bożego Narodzenia',
              },
              {
                src: img5,
                sign:
                  'Kapliczka Bożego Narodzenia w Pawilonie Polskim, 1925 r.',
              },
            ]}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: '3rem',
            }}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/bNltcmTQ70c"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Container>
      </ColorWrapper>
    </PageContainer>
  );
};

export default GrandPrixPage;
